import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { kebabCase } from 'lodash'
import Layout from '../components/Layout'
import BlogPosts from '../components/BlogPosts'
import AboutSummary from '../components/AboutSummary'

export const TagPageTemplate = ({
  blogPosts,
  allTags
}) => (
  <div>
    <div className="container max-w-postpage mx-auto">
      <div className="flex flex-wrap justify-around">
        <div className="w-full lg:w-content mx-4 lg:mx-0">
          <BlogPosts blogPosts={blogPosts}></BlogPosts>
        </div>
        <div className="w-full lg:w-sidebar h-about">
          <AboutSummary></AboutSummary>
          <div className="mx-4 lg:mx-0">
            <h2 className="text-xl mb-6 mt-12">Tags</h2>
            {allTags && allTags.map(tag => (
              <div key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
)

TagPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const TagPage= ({ data }) => {
  const blogPosts = data.blogPosts
  const group = data.allMarkdownRemark.group

  return (
    <Layout>
      <TagPageTemplate 
        blogPosts={blogPosts}
        allTags={group}
      ></TagPageTemplate>
    </Layout>
  )  
}

TagPage.propTypes = {
  data: PropTypes.shape({
    indexPage: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
    blogPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
    recentPosts: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default TagPage

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    tagPage: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { 
        frontmatter: { 
          templateKey: { eq: "blog-post" } 
          tags: { in: [$tag] }
        } 
      }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
            templateKey
            date(formatString: "YYYY.MM.DD")
            featuredpost
            # featuredimage {
            #   childImageSharp {
            #     fluid(maxWidth: 120, quality: 100) {
            #       ...GatsbyImageSharpFluid
            #     }
            #   }
            # }
          }
        }
      }
    }
  }
`
